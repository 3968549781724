import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthConsumer } from "./AuthContext";
import { isUndefined } from "util";

// will redirect to /login if user isn't logged in
const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentType<any>;
  [key: string]: any;
}) => (
  <AuthConsumer>
    {({ currentUser }) => (
      <Route
        render={props =>
          isUndefined(currentUser) ? (
            <Redirect to="/login" />
          ) : (
            <Component {...props} />
          )
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);
export default PrivateRoute;
