import * as React from "react";
import { Query } from "react-apollo";
import { GET_GROUP_PROFILE } from "../graphql/queries/group";
import { GetGroupProfileQueryData } from "../types/types";
import { withRouter, RouteComponentProps } from "react-router";
import { userProfileLink } from "../util/urls";
import { formatTimestamp } from "../util/helpers";
import { Loader } from "semantic-ui-react";

export class GroupProfile extends React.Component<
  RouteComponentProps<{ name: string }>
> {
  render() {
    const { name } = this.props.match.params;
    return (
      <Query<GetGroupProfileQueryData>
        query={GET_GROUP_PROFILE}
        variables={{ name }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loader active />;

          if (error) return `Error! ${error.message}`;
          if (!data) return "Error! No data.";
          const { group } = data;
          return (
            <div style={{ display: "grid", justifyContent: "center" }}>
              <em style={{ color: "gray" }}>{group.access}</em>
              <h3>{group.name}'s members</h3>
              <div>
                {group.userGroups &&
                  group.userGroups.map((ug, i) => {
                    return (
                      <div key={i}>
                        {userProfileLink(ug.user.username)}{" "}
                        <em style={{ color: "gray" }}>
                          &bull; member since {formatTimestamp(ug.createdAt)}
                          {ug.role.name === "admin" && (
                            <label> &bull; admin</label>
                          )}
                        </em>
                      </div>
                    );
                  })}
              </div>
              <h3>{group.name}'s values</h3>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "200px 40px"
                }}
              >
                {group.groupValues &&
                  group.groupValues.map((gv, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div>{gv.value.name}</div>
                        <div>{Math.round(gv.elo)}</div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

withRouter(GroupProfile);
