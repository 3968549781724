import gql from "graphql-tag";

export const CREATE_RESPONSE = gql`
  mutation createResponse(
    $questionId: ID!
    $valueId: ID
    $presentedAt: Date
    $respondedAt: Date
    $responseTime: Int
  ) {
    createResponse(
      questionId: $questionId
      valueId: $valueId
      presentedAt: $presentedAt
      respondedAt: $respondedAt
      responseTime: $responseTime
    ) {
      id
    }
  }
`;
