import * as React from "react";
import { Mutation } from "react-apollo";
import { CREATE_GROUP } from "../graphql/mutations/group";
import { startCase, uniqueId } from "lodash";
import { CreateGroupMutationData } from "../types/types";
import { ValidationError } from "../shared/types";
import { Redirect } from "react-router";
import { groupProfileUrl } from "../util/urls";
import { CURRENT_USER } from "../graphql/queries/user";

interface NewGroupState {
  name: string;
  access: "public" | "private";
}

// This is the form for creating a new Group

export class NewGroup extends React.Component<{}, NewGroupState> {
  state: NewGroupState = {
    name: "",
    access: "public"
  };

  onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      name: event.currentTarget.value
    });
  };

  onAccessChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      access: event.target.value
    } as Pick<NewGroupState, "access">);
  };

  renderError = (err: ValidationError) => {
    return (
      <div key={uniqueId()} style={{ background: "red" }}>
        {err.msg}
      </div>
    );
  };

  render() {
    const { name, access } = this.state;
    return (
      <Mutation<CreateGroupMutationData>
        mutation={CREATE_GROUP}
        refetchQueries={() => [{ query: CURRENT_USER }]}
      >
        {(createGroup, { data, error, loading }) => {
          if (data && data.createGroup) {
            return <Redirect to={groupProfileUrl(data.createGroup.name)} />;
          }
          if (error) {
            console.log("error", error);
          }
          return (
            <div style={{ display: "grid", justifyContent: "center" }}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  createGroup({
                    variables: { name: name, access: access }
                  });
                }}
              >
                <h3>Create a Group</h3>
                <div>
                  <label>
                    Group name:&nbsp;
                    <input
                      autoFocus
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.onNameChange}
                    />
                  </label>
                </div>
                {error &&
                  error.graphQLErrors.map(err => {
                    // TODO: this seems ugly and brittle
                    const validationErrors: ValidationError[] =
                      err &&
                      err.extensions &&
                      err.extensions.exception.validationErrors;
                    if (validationErrors) {
                      return validationErrors
                        .filter(e => e.param === "name")
                        .map(this.renderError);
                    }
                  })}
                <div>
                  <label>This group is </label>
                  <select value={access} onChange={this.onAccessChange}>
                    {["public", "private"].map(accessLevel => {
                      return (
                        <option value={accessLevel} key={accessLevel}>
                          {startCase(accessLevel)}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <button type="submit" disabled={loading}>
                  Create group
                </button>
              </form>
            </div>
          );
        }}
      </Mutation>
    );
  }
}
