import * as React from "react";
import { uniqueId } from "lodash";
import { AuthError } from "../../shared/types";
import { AuthConsumer } from "../../AuthContext";
import { isUndefined } from "util";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

interface LoginState {
  email: string;
  password: string;
}

export class Login extends React.Component<{}, LoginState> {
  state: LoginState = {
    email: "",
    password: ""
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<LoginState, "email" | "password">);
  };

  renderError = (err: AuthError) => {
    return (
      <div key={uniqueId()} style={{ background: "red" }}>
        {err.msg}
      </div>
    );
  };

  render() {
    const { email, password } = this.state;
    return (
      <AuthConsumer>
        {({ login, errors, currentUser }) => {
          if (isUndefined(currentUser)) {
            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  login(email, password);
                }}
              >
                <h3>Log in</h3>
                <div>
                  <label>
                    Email:
                    <input
                      autoFocus
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                    />
                  </label>
                </div>
                {errors &&
                  errors
                    .filter(err => err.param === "email")
                    .map(this.renderError)}
                <div>
                  <label>
                    Password:
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                  </label>
                </div>
                {errors &&
                  errors
                    .filter(err => err.param === "password")
                    .map(this.renderError)}
                <button type="submit">Log in</button>
                <div>
                  Don't have an account? <Link to="/signup">Sign up</Link>{" "}
                </div>
              </form>
            );
          } else {
            return <Redirect to="/ask" />;
          }
        }}
      </AuthConsumer>
    );
  }
}
