import * as React from "react";
import { Link } from "react-router-dom";

export const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.libraryofvalues.org"
    : "";

// URL to a user's profile
export const userProfileUrl = (username: string) => {
  return `/user/${username}`;
};

// Link to a user's profile
export const userProfileLink = (username: string) => {
  return <Link to={`/user/${username}`}>{username}</Link>;
};

// URL to a group's profile
export const groupProfileUrl = (name: string) => {
  return `/group/${name}`;
};

// Link to a group's profile
export const groupProfileLink = (name: string) => {
  return <Link to={groupProfileUrl(name)}>{name}</Link>;
};

export const newGroupLink = () => {
  return <Link to="/group/new">Create a group...</Link>;
};
