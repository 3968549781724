// takes a timestamp, returns formatted day string, like 3/29/2019
export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-US");
};

export const asyncForEach = async (
  array: any[],
  callback: (item: any, index: number, originalArray: any[]) => any
) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};
