import gql from "graphql-tag";

export const CREATE_GROUP = gql`
  mutation createGroup($name: String!, $access: String!) {
    createGroup(name: $name, access: $access) {
      id
      name
    }
  }
`;
