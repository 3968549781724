import * as React from "react";
import { uniqueId } from "lodash";
import { AuthError } from "../../shared/types";
import { AuthConsumer } from "../../AuthContext";
import { isUndefined } from "util";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

interface SignupState {
  email: string;
  password: string;
  username: string;
}

export class Signup extends React.Component<{}, SignupState> {
  state: SignupState = {
    email: "",
    password: "",
    username: ""
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.currentTarget.name]: event.currentTarget.value
    } as Pick<SignupState, "email" | "password" | "username">);
  };

  renderError = (err: AuthError) => {
    return (
      <div key={uniqueId()} style={{ background: "red" }}>
        {err.msg}
      </div>
    );
  };

  render() {
    const { email, password, username } = this.state;
    return (
      <AuthConsumer>
        {({ signup, errors, currentUser }) => {
          if (isUndefined(currentUser)) {
            return (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  signup(email, username, password);
                }}
              >
                <h3>Sign up</h3>
                <div>
                  <label>
                    Email:
                    <input
                      autoFocus
                      type="text"
                      name="email"
                      value={email}
                      onChange={this.onChange}
                    />
                  </label>
                </div>
                {errors &&
                  errors
                    .filter(err => err.param === "email")
                    .map(this.renderError)}
                <div>
                  <label>
                    Username:
                    <input
                      type="text"
                      name="username"
                      value={username}
                      onChange={this.onChange}
                    />
                  </label>
                </div>
                {errors &&
                  errors
                    .filter(err => err.param === "username")
                    .map(this.renderError)}
                <div>
                  <label>
                    Password:
                    <input
                      type="password"
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                  </label>
                </div>
                {errors &&
                  errors
                    .filter(err => err.param === "password")
                    .map(this.renderError)}
                <button type="submit">Sign up</button>
                <div>
                  Already have an account? <Link to="/login">Log in</Link>{" "}
                </div>
              </form>
            );
          } else {
            return <Redirect to="/" />;
          }
        }}
      </AuthConsumer>
    );
  }
}
