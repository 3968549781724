import gql from "graphql-tag";

export const CURRENT_USER = gql`
  {
    currentUser {
      id
      username
      groups {
        id
        name
      }
    }
  }
`;

export const ALL_USERS = gql`
  {
    allUsers {
      id
      username
      email
      createdAt
      updatedAt
      # createdValues {
      #   name
      # }
    }
  }
`;

export const GET_USER_PROFILE = gql`
  query getUserProfile($username: String) {
    user(username: $username) {
      id
      username
      userValues {
        elo
        value {
          id
          name
        }
      }
      groups {
        id
        name
      }
    }
  }
`;

// should this be a query or a mutation?  it does findOrCreate, which is both...
export const FETCH_QUESTION = gql`
  {
    fetchQuestion {
      question {
        id
        values {
          id
          name
        }
      }
      prevResponse {
        responseTime
        selectedElo
        unselectedElo
        question {
          id
          values {
            id
            name
          }
        }
        value {
          id
          name
        }
      }
    }
  }
`;
