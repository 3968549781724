import * as React from "react";
import { Query } from "react-apollo";
import { ALL_USERS } from "../graphql/queries/user";
import { User, Value } from "../types/types";
import { formatTimestamp } from "../util/helpers";
import { ALL_VALUES } from "../graphql/queries/value";
import { userProfileLink } from "../util/urls";
import { Loader } from "semantic-ui-react";

export function Admin() {
  return (
    <div>
      <h2>All users</h2>
      <Query query={ALL_USERS}>
        {({ loading, error, data }) => {
          if (loading) return <Loader active />;
          if (error) return `Error! ${error.message}`;
          const allUsers: User[] = data.allUsers;
          return (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)"
              }}
            >
              {["Username", "Email", "Created At", "Updated At", "ID"].map(
                (headerName, i) => {
                  return (
                    <div key={i}>
                      <strong>{headerName}</strong>
                    </div>
                  );
                }
              )}
              {allUsers.map((user, i) =>
                ["username", "email", "createdAt", "updatedAt", "id"].map(
                  field => {
                    const fieldValue = user[field];
                    return (
                      <div key={`${i}-${field}`}>
                        {["createdAt", "updatedAt"].includes(field)
                          ? formatTimestamp(fieldValue)
                          : field === "username"
                          ? userProfileLink(fieldValue)
                          : fieldValue}
                      </div>
                    );
                  }
                )
              )}
            </div>
          );
        }}
      </Query>
      <h2>All values</h2>
      <Query query={ALL_VALUES}>
        {({ loading, error, data }) => {
          if (loading) return <Loader active />;
          if (error) return `Error! ${error.message}`;
          const allValues: Value[] = data.allValues;
          return (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(5, 1fr)"
              }}
            >
              {["Name", "Creator", "Created At", "Updated At", "ID"].map(
                (headerName, i) => {
                  return (
                    <div key={i}>
                      <strong>{headerName}</strong>
                    </div>
                  );
                }
              )}
              {allValues.map((value, i) =>
                ["name", "creator", "createdAt", "updatedAt", "id"].map(
                  field => {
                    const fieldValue = value[field];
                    return (
                      <div key={`${i}-${field}`}>
                        {["createdAt", "updatedAt"].includes(field)
                          ? formatTimestamp(fieldValue)
                          : field === "creator"
                          ? userProfileLink(fieldValue.username)
                          : fieldValue}
                      </div>
                    );
                  }
                )
              )}
            </div>
          );
        }}
      </Query>
    </div>
  );
}
