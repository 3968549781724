import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { AuthConsumer } from "./AuthContext";
import { Login } from "./components/auth/Login";
import { Signup } from "./components/auth/Signup";
import { AskQuestion } from "./components/AskQuestion";
import { Admin } from "./components/Admin";
import { groupProfileUrl, userProfileUrl } from "./util/urls";
import { UserProfile } from "./components/UserProfile";
import { GroupProfile } from "./components/GroupProfile";
import { NewGroup } from "./components/NewGroup";

import { Sidebar, Menu, Icon, Accordion, Label } from "semantic-ui-react";

import styled from "styled-components";
import { Results } from "./components/Results";
import { LOVCSS } from "./util/lovcss";

interface AppLayoutState {
  sidebarOpen: boolean;
  sidebarGroupsOpen: boolean;
}

class AppLayout extends React.Component<
  { className?: string },
  AppLayoutState
> {
  state: AppLayoutState = {
    sidebarOpen: false,
    sidebarGroupsOpen: true
  };

  openSidebar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    this.setState({ sidebarOpen: true });
  };
  closeSidebar = () => {
    this.setState({ sidebarOpen: false });
  };
  toggleSidebarGroups = () => {
    this.setState({ sidebarGroupsOpen: !this.state.sidebarGroupsOpen });
  };

  renderMenuItemLink = (to: string, text: string, index?: number) => {
    return (
      <Menu.Item
        key={index}
        as={Link}
        to={to}
        onClick={e => {
          e.stopPropagation();
          this.closeSidebar();
        }}
      >
        {text}
      </Menu.Item>
    );
  };

  render() {
    const { className } = this.props;
    const { sidebarOpen, sidebarGroupsOpen } = this.state;
    return (
      <div className={className}>
        <Router>
          <AuthConsumer>
            {({ currentUser, logout }) =>
              currentUser ? (
                <>
                  <Sidebar.Pushable>
                    <Sidebar
                      as={StyledMenu}
                      animation="overlay"
                      vertical
                      visible={sidebarOpen}
                      width="wide"
                    >
                      <Accordion vertical>
                        <Menu.Item
                          as="a"
                          onClick={this.closeSidebar}
                          style={{ textAlign: "right" }}
                        >
                          <Icon
                            name="angle left"
                            size="large"
                            style={{ float: "none" }}
                          />
                          Close menu
                        </Menu.Item>
                        {this.renderMenuItemLink("/ask", "Which resonates?")}
                        {this.renderMenuItemLink(
                          userProfileUrl(currentUser.username),
                          "Your values"
                        )}
                        <Menu.Item
                          as="a"
                          onClick={this.toggleSidebarGroups}
                          style={{
                            padding: "13px 0 13px 16px"
                          }}
                        >
                          <Accordion.Title active={sidebarGroupsOpen}>
                            {sidebarGroupsOpen ? (
                              <Icon name="triangle down" />
                            ) : (
                              <Icon name="triangle right" />
                            )}
                            Your groups{" "}
                            <Label circular>{currentUser.groups.length}</Label>
                          </Accordion.Title>
                          <Accordion.Content active={sidebarGroupsOpen}>
                            <Menu.Menu
                              style={{
                                maxHeight: "150px",
                                overflow: "auto"
                              }}
                            >
                              {currentUser.groups.map((g, i) => {
                                return this.renderMenuItemLink(
                                  groupProfileUrl(g.name),
                                  g.name,
                                  i
                                );
                              })}
                            </Menu.Menu>
                          </Accordion.Content>
                        </Menu.Item>
                        {this.renderMenuItemLink(
                          "/group/new",
                          "Create a new group"
                        )}
                        {this.renderMenuItemLink("/admin", "Admin")}
                        <Menu.Item as="a" onClick={logout}>
                          Log out
                        </Menu.Item>
                      </Accordion>
                    </Sidebar>
                    <Sidebar.Pusher
                      dimmed={sidebarOpen}
                      onClick={this.closeSidebar}
                    >
                      <TopBar onMenuClick={this.openSidebar} />
                      <AppRoutes />
                    </Sidebar.Pusher>
                  </Sidebar.Pushable>
                </>
              ) : (
                <AppRoutes />
              )
            }
          </AuthConsumer>
        </Router>
      </div>
    );
  }
}
const TopBar = styled(
  ({
    className,
    onMenuClick
  }: {
    className?: string;
    onMenuClick: (e: React.MouseEvent<HTMLElement>) => void;
  }) => {
    return (
      <Menu className={className} fixed="top">
        <Menu.Item onClick={onMenuClick}>
          <Icon name="sidebar" />
          Library of Values
        </Menu.Item>
      </Menu>
    );
  }
)`
  &&& {
    background: ${LOVCSS.backgroundColor};
    .item {
      color: ${LOVCSS.textColor};
    }
    .item:hover {
      background: ${LOVCSS.lightTextColor};
      color: ${LOVCSS.backgroundColor};
    }
  }
`;

const AppRoutes = styled(({ className, history }: any) => {
  return (
    <div className={className}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={Signup} />
        <PrivateRoute exact path="/ask" component={AskQuestion} />
        <PrivateRoute exact path="/results" component={Results} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute path="/user/:username" component={UserProfile} />
        <PrivateRoute exact path="/group/new" component={NewGroup} />
        <PrivateRoute path="/group/:name" component={GroupProfile} />
        <Route path="/*" render={() => <Redirect to="/login" />} />
      </Switch>
    </div>
  );
})`
  padding-top: 65px;
  display: flex;
  justify-content: center;
`;

const StyledMenu = styled(Menu)`
  &&&& {
    .ui.accordion .title:not(.ui) {
      padding: 0;
    }
  }
`;
export default styled(AppLayout)`
  color: ${LOVCSS.textColor};
  background: ${LOVCSS.backgroundColor};
  height: 100vh;
`;
