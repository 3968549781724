import * as React from "react";
import { CREATE_RESPONSE } from "../graphql/mutations/response";
import { WithApolloClient, Query, Mutation } from "react-apollo";
import { FETCH_QUESTION } from "../graphql/queries/user";
import { Question, Value, FetchQuestionQueryData } from "../types/types";
import { times } from "lodash";
import { Icon, Loader } from "semantic-ui-react";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router";
import { LOVCSS } from "../util/lovcss";

interface AskQuestionState {
  responseCount: number;
}

class AskQuestionUnwrapped extends React.Component<
  WithApolloClient<RouteComponentProps<{}>>,
  AskQuestionState
> {
  state: AskQuestionState = {
    responseCount: 0
  };

  render() {
    const { responseCount } = this.state;
    const { history } = this.props;
    return (
      <Query<FetchQuestionQueryData>
        query={FETCH_QUESTION}
        fetchPolicy="cache-and-network"
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loader active />;
          if (error) return `Error! ${error.message}`;
          if (!data) return "Error! No data.";

          // console.log(data.fetchQuestion);
          const q: Question = data.fetchQuestion.question;
          // const { prevResponse } = data.fetchQuestion;
          const presentedAt = Date.now();
          // const [v0, v1] = q.values;
          // console.log("rc", responseCount);
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                width: "90vw",
                maxWidth: "500px"
              }}
            >
              <h3
                style={{
                  marginTop: "20px",
                  fontSize: "30px",
                  color: LOVCSS.lightTextColor
                }}
              >
                Which resonates more?
              </h3>
              <Mutation mutation={CREATE_RESPONSE}>
                {mutate => (
                  <div style={{ margin: "50px 0" }}>
                    {q.values.map((v, i) => {
                      return (
                        <React.Fragment key={i}>
                          <button
                            style={{
                              display: "block",
                              cursor: "pointer",
                              background: LOVCSS.backgroundColor,
                              fontSize: "40px",
                              color: LOVCSS.textColor,
                              border: `1px solid ${LOVCSS.lightTextColor}`,
                              padding: "30px 15px",
                              marginBottom: "20px",
                              width: "100%"
                              // textTransform: "uppercase"
                            }}
                            onClick={e => {
                              const respondedAt = Date.now();
                              const responseTime = respondedAt - presentedAt;
                              return mutate({
                                variables: {
                                  questionId: q.id,
                                  valueId: v.id,
                                  presentedAt,
                                  respondedAt,
                                  responseTime
                                }
                              })
                                .then(mutationResponse => {
                                  // console.log(mutationResponse);
                                  return this.setState(
                                    state => {
                                      return {
                                        responseCount: state.responseCount + 1
                                      };
                                    },
                                    () => {
                                      // console.log(this.state.responseCount);
                                      if (this.state.responseCount > 9) {
                                        return history.push("/results");
                                      }
                                      refetch();
                                    }
                                  );
                                })
                                .catch(err => console.log(err));
                            }}
                          >
                            {v.name}
                          </button>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </Mutation>
              <StyledResponseProgress responseCount={responseCount} />
              {/* {prevResponse && (
                <div>
                  <h4>Previous question</h4>
                  {prevResponse.question.values.map((v: Value, i: number) => {
                    return (
                      <React.Fragment key={i}>
                        {v.id === prevResponse.value.id ? (
                          <span style={{ fontWeight: "bold" }}>
                            {v.name} ({prevResponse.selectedElo})
                          </span>
                        ) : (
                          <span>
                            {v.name} ({prevResponse.unselectedElo})
                          </span>
                        )}
                        <br />
                      </React.Fragment>
                    );
                  })}
                  Response time: {prevResponse.responseTime / 1000} seconds
                </div>
              )} */}
            </div>
          );
        }}
      </Query>
    );
  }
}
export const AskQuestion = withRouter(AskQuestionUnwrapped);

function ResponseMarker(props: { responded: boolean }) {
  return (
    <div
      className="response-marker"
      style={{
        background: props.responded ? LOVCSS.lightTextColor : undefined
      }}
    />
  );
}

function ResponseProgress({
  className,
  responseCount
}: {
  className?: string;
  responseCount: number;
}) {
  return (
    <div className={className}>
      {times(10, i => {
        return <ResponseMarker key={i} responded={responseCount > i} />;
      })}
      <Icon name={`flag${responseCount > 9 ? "" : " outline"}` as any} />
    </div>
  );
}
const StyledResponseProgress = styled(ResponseProgress)`
  display: flex;
  justify-content: space-between;
  .response-marker {
    border: 1px solid ${LOVCSS.lightTextColor};
    width: 10px;
    height: 10px;
    margin: 4px 3px 0;
  }
`;
