import * as React from "react";
import { Query } from "react-apollo";
import { GET_USER_PROFILE } from "../graphql/queries/user";
import { GetUserProfileQueryData } from "../types/types";
import { withRouter, RouteComponentProps } from "react-router";
import { groupProfileLink } from "../util/urls";
import { Loader } from "semantic-ui-react";

export class UserProfile extends React.Component<
  RouteComponentProps<{ username: string }>
> {
  render() {
    const { username } = this.props.match.params;
    return (
      <Query<GetUserProfileQueryData>
        query={GET_USER_PROFILE}
        variables={{ username }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <Loader active />;
          if (error) return `Error! ${error.message}`;
          if (!data) return "Error! No data.";
          const { user } = data;
          return (
            <div style={{ display: "grid", justifyContent: "center" }}>
              <h3>{user.username}'s groups</h3>
              <div>
                {user.groups &&
                  user.groups.map((g, i) => {
                    return <div key={i}>{groupProfileLink(g.name)}</div>;
                  })}
              </div>
              <h3>{user.username}'s values</h3>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "200px 40px"
                }}
              >
                {user.userValues &&
                  user.userValues.map((uv, i) => {
                    return (
                      <React.Fragment key={i}>
                        <div>{uv.value.name}</div>
                        <div>{Math.round(uv.elo)}</div>
                      </React.Fragment>
                    );
                  })}
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

withRouter(UserProfile);
