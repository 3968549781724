import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { AuthProvider } from "./AuthContext";
import AppLayout from "./AppLayout";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import "semantic-ui-css/semantic.min.css";
import { API_URL } from "./util/urls";

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) =>
      console.log(`[APOLLO] ERROR: ${message}`)
    );
});
const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`,
  credentials: "same-origin"
});
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache()
});

const RootApp = () => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <AppLayout />
    </AuthProvider>
  </ApolloProvider>
);

ReactDOM.render(<RootApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
