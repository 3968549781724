// see https://medium.freecodecamp.org/how-to-protect-your-routes-with-react-context-717670c4713a

import React from "react";
import { apolloClient } from "./index";
import { Query } from "react-apollo";
import { AuthError, AuthResponse } from "./shared/types";
import { Group } from "./types/types";
import { CURRENT_USER } from "./graphql/queries/user";
import { Loader } from "semantic-ui-react";
import { API_URL } from "./util/urls";

interface AuthProviderState {
  errors?: AuthError[];
  currentUser?: { id: string; username: string; groups: Group[] };
  signup(email: string, username: string, password: string): void;
  login(email: string, password: string): void;
  logout(): void;
}
const defaultValue: AuthProviderState = {
  errors: undefined,
  currentUser: undefined,
  signup: (email: string, username: string, password: string) => null,
  login: (email: string, password: string) => null,
  logout: () => null
};

const AuthContext = React.createContext(defaultValue);

class AuthProvider extends React.Component<{}, AuthProviderState> {
  state: AuthProviderState = {
    errors: undefined,
    currentUser: undefined,
    signup: (email: string, username: string, password: string) => null,
    login: (email: string, password: string) => null,
    logout: () => false
  };

  signup = (email: string, username: string, password: string) => {
    fetch(`${API_URL}/signup`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, username, password })
    })
      .then(response => response.json())
      .then((response: AuthResponse) => {
        this.setState({
          errors: response.errors,
          currentUser: response.currentUser
        });
      });
  };

  login = (email: string, password: string) => {
    fetch(`${API_URL}/login`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password })
    })
      .then(response => response.json())
      .then((response: AuthResponse) => {
        this.setState({
          errors: response.errors,
          currentUser: response.currentUser
        });
      });
  };

  logout = () => {
    fetch(`${API_URL}/logout`, {
      method: "get"
    }).then(response => {
      apolloClient.resetStore();
      this.setState({ currentUser: undefined, errors: undefined });
    });
  };

  render() {
    const { errors, currentUser } = this.state;
    return (
      <Query query={CURRENT_USER}>
        {({ loading, error, data }) => {
          if (loading) return <Loader active />;
          // if (error) return `Error! ${error.message}`;
          console.log(loading, error, data);
          return (
            <AuthContext.Provider
              value={{
                errors,
                currentUser: currentUser || (data && data.currentUser),
                signup: this.signup,
                login: this.login,
                logout: this.logout
              }}
            >
              {this.props.children}
            </AuthContext.Provider>
          );
        }}
      </Query>
    );
  }
}
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer };
