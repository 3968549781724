import gql from "graphql-tag";

// this fetches the current user's 10 more recent responses for the Responses component
export const RESPONSE_LOG = gql`
  query getRecentResponses($groupId: String!) {
    recentResponses(groupId: $groupId) {
      question {
        id
        values {
          id
          name
          #selected
        }
      }
      value {
        id
        name
      }
      agreePercent
    }
  }
`;
