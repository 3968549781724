import gql from "graphql-tag";

export const ALL_VALUES = gql`
  {
    allValues {
      id
      name
      createdAt
      updatedAt
      creator {
        username
      }
    }
  }
`;
