import gql from "graphql-tag";

export const ALL_GROUPS = gql`
  {
    allGroups {
      id
      name
      userGroups {
        role
        createdAt
        user {
          id
          username
        }
      }
      createdAt
      updatedAt
      # createdValues {
      #   name
      # }
    }
  }
`;

export const GET_GROUP_PROFILE = gql`
  query getGroupProfile($name: String!) {
    group(name: $name) {
      id
      name
      access
      userGroups {
        role {
          name
        }
        createdAt
        user {
          id
          username
        }
      }
      groupValues {
        elo
        value {
          id
          name
        }
      }
    }
  }
`;
