import * as React from "react";
import styled from "styled-components";
import { Query } from "react-apollo";
import { RESPONSE_LOG } from "../graphql/queries/response";
import { ResultsQueryData, Response, Group } from "../types/types";
import { isUndefined } from "util";
import { Loader, Dropdown } from "semantic-ui-react";
import { isEqual } from "apollo-utilities";
import { LOVCSS } from "../util/lovcss";
import { AuthConsumer } from "../AuthContext";
import { Link } from "react-router-dom";

// interface ResultsState {
//   compareGroup?: Group;
// }
export const Results: React.FunctionComponent = () => {
  const [compareGroupId, setCompareGroupId] = React.useState<
    string | undefined
  >(undefined);
  return (
    <AuthConsumer>
      {({ currentUser }) => {
        const allGroups = currentUser!.groups;
        const currentGroup = compareGroupId
          ? allGroups.find(g => g.id === compareGroupId)
          : allGroups[0];
        return (
          <Query<ResultsQueryData>
            query={RESPONSE_LOG}
            variables={{ groupId: currentGroup!.id }}
            fetchPolicy="cache-and-network"
          >
            {({ data, loading }) => {
              if (loading || isUndefined(data)) {
                return <Loader active />;
              }
              return (
                <ResultsTable
                  responseStats={data.recentResponses}
                  allGroups={allGroups}
                  compareGroup={currentGroup!}
                  setCompareGroupId={setCompareGroupId}
                />
              );
            }}
          </Query>
        );
      }}
    </AuthConsumer>
  );
};
// export class Results extends React.Component {
//   state: ResultsState = {
//     compareGroup: undefined
//   };
//   render() {}
// }

class ResultsTableUnstyled extends React.Component<{
  className?: string;
  responseStats: Response[];
  allGroups: Group[];
  compareGroup: Group;
  setCompareGroupId: (groupId?: string) => void;
}> {
  render() {
    const {
      className,
      responseStats,
      allGroups,
      compareGroup,
      setCompareGroupId
    } = this.props;
    return (
      <table className={className}>
        <thead>
          <tr>
            <td />
            <td style={{ textAlign: "center", fontWeight: "bold" }}>
              <Dropdown
                inline
                scrolling
                direction="right"
                onChange={(e, data) => setCompareGroupId(data.value as string)}
                options={allGroups.map(g => {
                  return { text: g.name, value: g.id };
                })}
                defaultValue={compareGroup.id}
              />
            </td>
          </tr>
        </thead>
        <tbody>
          {responseStats.map((rs, i) => (
            <ResponseStat key={i} response={rs} />
          ))}
          <tr>
            <td colSpan={2} style={{ borderBottom: 0 }}>
              <Link to="/ask">Answer more questions</Link>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
const ResultsTable = styled(ResultsTableUnstyled)`
  border-collapse: collapse;
  width: 60vw;
  max-width: 500px;
  td {
    border-bottom: 1px solid ${LOVCSS.lightTextColor};
    padding: 8px 0;
  }
`;

// interface ResponseStat {
//   values: Array<{ name: string; selected?: boolean }>;
//   agreePercent: number;
// }

function ResponseStat(props: { response: Response }) {
  const { response } = props;
  return (
    <tr>
      <td>
        {response.question.values.map((v, i) => {
          const isSelected = isEqual(v, response.value);
          return (
            <div
              key={i}
              style={{
                color: isSelected ? LOVCSS.textColor : LOVCSS.lightTextColor
              }}
            >
              {v.name}
            </div>
          );
        })}
      </td>
      <td
        style={{
          textAlign: "center",
          fontWeight: "bold",
          verticalAlign: "middle"
        }}
      >
        {response.agreePercent}%{" "}
        <span style={{ color: LOVCSS.lightTextColor, fontSize: "0.8em" }}>
          agree
        </span>
      </td>
    </tr>
  );
}
